<template>
  <div class="setting">
    <topbar title="设置" background="#e7141a" />
    <div class="vanCellList">
      <van-cell-group>
        <van-cell title="同步用户信息" @click="syncUserInfo">
          <van-loading v-if="loading" size="18px" color="#e7141a" />
          <template class="tip" v-else>同步小程序头像和昵称 <van-icon name="arrow"/></template>
        </van-cell>
        <!-- <van-cell title="注销分销员账号" @click="toLogoff">
          <van-loading v-if="loading" size="18px" color="#e7141a" />
          <div class="tip" v-else>注销后无法恢复，请谨慎操作 <van-icon name="arrow" /></div>
        </van-cell> -->
      </van-cell-group>
      <div class="divide"></div>
      <van-cell-group>
        <van-cell title="签约设置" @click="toSign">{{ signText }}<van-icon name="arrow"/></van-cell>
        <van-cell title="提现设置" @click="toWithdraw">
          <van-icon name="arrow"
        /></van-cell>
      </van-cell-group>
      <div class="divide"></div>
      <van-cell-group>
        <van-cell title="清除缓存" @click="clear"><van-icon name="arrow"/></van-cell>
      </van-cell-group>
      <div class="divide"></div>
      <van-cell-group>
        <van-cell title="《自由职业者服务协议》" @click="goProtocal"
          ><van-icon name="arrow"
        /></van-cell>
      </van-cell-group>
      <div class="divide"></div>
      <div class="btn">
        <van-button @click="logout" plain type="danger" block>退出登录</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Notify, Dialog, Loading } from 'vant'
export default {
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo'
    }),
    signText() {
      if (this.userInfo.signStatus) {
        return '已签约'
      } else {
        return '未签约'
      }
    }
  },
  methods: {
    goProtocal() {
      this.$router.push('/protocol')
    },
    toSign() {
      if (this.userInfo.signStatus) {
        this.$router.push('/userSign?step=3')
      } else {
        this.$router.push('/userSign')
      }
    },
    toWithdraw() {
      this.$router.push('/withdraw/setting')
    },
    logout() {
      this.$store.dispatch('user/logout')
      this.$router.replace('/')
    },
    toLogoff() {
      this.$router.push('setting/logoff')
    },
    clear() {
      for (var i = 0; i < localStorage.length; i++) {
        var key = localStorage.key(i) //获取本地存储的Key
        if (!['token', 'vuex', 'tenantId'].includes(key)) {
          localStorage[key] = ''
        }
      }
      Notify({
        type: 'success',
        message: '清除成功'
      })
    },
    syncUserInfo() {
      this.loading = true
      this.$http
        .get('/boom-center-user-service/app/distributionMember/syncUserInfo')
        .then(res => {
          Notify({
            type: 'success',
            message: '同步成功'
          })
        })
        .catch(err => {
          Notify({
            type: 'danger',
            message: err.msg || '同步失败'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.setting {
  background: #f9f9f9;
  height: 100vh;

  .vanCellList {
    margin: 10px;
    .divide {
      height: 0;
      margin: 10px 0;
    }
  }
}
.van-cell__value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
